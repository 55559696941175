/**
 * @TODO REFACTOR THIS CRAZY SHIT
 * @type {Function}
 */
let FormStatus = (function () {

    let _private = {};

    _private.instance = this;

    _private.statusIcon = $();

    _private.statusMessage = $();

    _private.saveButton = $();

    _private.statusStore = {};

    _private.header = {};

    this.init = function (wrapper) {
        wrapper                = wrapper || $('div.state-control');
        _private.statusIcon    = wrapper.find('div.state-icon');
        console.log(_private.statusIcon);
        _private.statusMessage = wrapper.find('div.state-message');
        _private.saveButton    = $(new FormObserver().getSaveButtonSelector());
        $(_private.saveButton).unbind('click.resetStatusMessage').on('click.resetStatusMessage',
            function () {
                _private.instance.resetStatusMessage();
            }
        );
        setTimeout(function () {
            $('.statusMessage.success').fadeOut('fast');
        }, 1000);
        $('.statusMessage').click(function () {
            $(this).fadeOut('fast');
        });
        return this;
    };

    this.resetStatusMessage = function () {
        _private.statusStore = {};
        _private.updateFromStore();
        _private.statusMessage.find('ul li').remove();
        return this;
    };

    this.getResponseStatus = function (response) {
        let responseStatus = 'unknown';
        if (_.isUndefined(response.status)) {
            return responseStatus;
        }
        if (!_.isObject(response.status)) {
            return response.status;
        }
        if (!_.isUndefined(response.status.key)) {
            return response.status.key;
        }
        return responseStatus;
    };

    /**
     *
     * @param type
     * @param id
     * @param message
     * @param element
     * @param objectType
     * @param errors
     * @returns {string|boolean}
     */
    this.addStatus = function (type, id, message, element, objectType, errors) {
        if (!_.isString(type)) {
            type = _private.parseType(type);
        }
        if (!_.isString(type)) {
            return false;
        }
        if (_.isUndefined(id) || $.trim(id) === '') {
            id = 'default';
        }
        if (_.isUndefined(message) || $.trim(message) === '') {
            let translateKey = 'status';
            if (!_.isUndefined(objectType) && $.trim(objectType) !== '') {
                translateKey += '.' + objectType;
            }
            translateKey += '.' + type;
            message = EfbHelper._(translateKey);
        }
        _private.statusStore[id] = {
            id:          id,
            icon_class:  _private.updateIcon(type),
            message:     message,
            type:        type,
            element:     element,
            errors:      errors,
            object_type: objectType
        };
        if ($.inArray(type, ['save_ok', 'success', 'delete_ok', 'success']) !== -1) {
            setTimeout(function () {
                $(_private.statusIcon).fadeOut('fast', function () {
                    _private.statusIcon.removeClass('success');
                    _private.statusIcon.fadeIn(100);
                });
            }, 2000);
        }
        return _private.statusStore[id]['icon_class'];
    };

    this.removeStatusMessage = function (id) {
        if (_.isUndefined(id) || $.trim(id) === '') {
            id = 'default';
        }
        if (_.isUndefined(_private.statusStore[id])) {
            return;
        }
        delete _private.statusStore[id];
        _private.updateFromStore();
    };

    _private.updateFromStore = function () {
        _private.statusIcon.removeClass('warning');
        _private.statusIcon.removeClass('success');
        _private.statusIcon.removeClass('error');
        $.each(_private.statusStore, function (id, status) {
            _private.statusIcon.addClass(status.icon_class);
        });
    };

    _private.parseType = function (type) {
        if (!_.isObject(type)) {
            return false;
        }
        if (!_.isUndefined(type.status)) {
            type = type.status;
        }
        if (!_.isUndefined(type.key)) {
            type = type.key;
        }
        if (!_.isString(type)) {
            return false;
        }
        return type;
    };

    _private.updateIcon = function (type) {
        let className = '';
        switch (true) {
            case $.inArray(type, ['save_ok', 'success', 'delete_ok', 'ok']) !== -1:
                className = 'success';
                break;
            case $.inArray(type, ['save_conflict', 'check_errors', 'delete_conflict', 'validation_error', 'warning']) !== -1:
                className = 'warning';
                break;
            case $.inArray(type, ['save_error', 'delete_failed', 'error']) !== -1:
                className = 'error';
                break;
            default:
                break;
        }
        _private.statusIcon.addClass(className);
        return className;
    };


    this.showStatusMessageContainer = function () {
        _private.statusMessage.show();
    };

    this.hideStatusMessageContainer = function () {
        _private.statusMessage.hide();
    };
});